import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';
import { typo } from '@styles/typo.styles';
import { breakpoints } from '@styles/variables.styles';

const base = css`
  width: 100%;
  padding: 100px 0;
  background-color: var(--color-background-2);
`;

const content = css`
  ${grid.basic6col};

  @media ${breakpoints.fromLrg} {
    ${grid.basic50col};
  }
`;

const leftSideWrapper = css`
  grid-column: 1 / span 5;

  @media ${breakpoints.fromMed} {
    grid-column: 1 / span 20;
  }

  @media ${breakpoints.fromXLrg} {
    grid-column: 10 / span 14;
  }
`;

const title = css`
  ${typo.from56to32};
  grid-column: 1 / span 6;
  color: var(--color-text-6);
  line-height: clamp(1.99rem, 1.47rem + 2.28vw, 3.75rem);
  font-weight: 700;
  margin-bottom: 20px;
  letter-spacing: -0.03em;
`;

const paragraph = css`
  color: var(--color-text-5);
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 80px;

  @media ${breakpoints.fromLrg} {
    margin-bottom: 140px;
  }
`;

const socialsWrapper = css`
  display: flex;
  gap: 24px;
  margin-bottom: 100px;
`;

const rightSideWrapper = css`
  grid-column: 1 / span 6;
  display: flex;
  flex-direction: column;

  @media ${breakpoints.fromMed} {
    grid-column: 21 / span 19;
  }

  @media ${breakpoints.fromLrg} {
    grid-column: 25 / span 25;
  }

  @media ${breakpoints.fromXLrg} {
    grid-column: 28 / span 12;
  }
`;

const formInputs = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-text-3);
  border-bottom: 1px solid var(--color-border-5);
  margin-bottom: 40px;
`;

const label = css`
  padding: 16px 0;
  margin-top: 0px;
`;

const input = css`
  color: var(--color-text-6);
  background: transparent;
  padding: 16px 0;
  min-width: 100%;

  &::placeholder {
    color: var(--color-text-3);
  }
`;

const formTextareaWrapper = css`
  margin-bottom: 40px;
`;

const textarea = css`
  color: var(--color-text-6);
  background: transparent;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--color-border-5);
  outline: none;

  &::placeholder {
    color: var(--color-text-3);
  }
`;

const privacyWrapper = css`
  display: flex;
  margin-bottom: 40px;
  gap: 12px;
`;

const privacyDescription = css`
  flex: 1;
  color: var(--color-text-4);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

const privacyLink = css`
  color: var(--color-general-primary);
  text-decoration: none;
  font-weight: 400;
`;

const checkbox = css`
  position: relative;
  width: 24px;
  height: 24px;
  border: 1px solid var(--color-border-6);
  border-radius: 5px;

  &::before {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: var(--color-general-primary);
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:checked::before {
    opacity: 1;
    width: 6px;
    height: 12px;
  }
`;

const button = css`
  display: flex;
  position: relative;
  background-color: var(--color-general-primary);
  color: var(--color-text-1);
  width: max-content;
  font-size: 16px;
  line-height: 24px;
  border-radius: 12px;
  border: none;
  padding: 10px 32px;
  gap: 12px;
  overflow: hidden;
  cursor: pointer;

  > span,
  svg {
    z-index: 1;
    font-family: var(--font-family-primarySemiBold);
  }

  &::before {
    content: '';
    position: absolute;
    background-color: white;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 12px;
    transition: width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  }

  &:hover::before {
    width: 100%;
  }
`;

const icon = css`
  width: 24px;
  height: 24px;

  & path {
    transition: all 0.2s ease-out;
    fill: var(--color-border-4);
  }

  &:hover {
    path {
      fill: var(--color-general-primary);
    }
  }
`;

export const styles = {
  base,
  content,
  leftSideWrapper,
  title,
  paragraph,
  socialsWrapper,
  rightSideWrapper,
  formInputs,
  label,
  input,
  formTextareaWrapper,
  textarea,
  privacyWrapper,
  checkbox,
  privacyDescription,
  privacyLink,
  button,
  icon,
};
