import { AnimatedText } from '@ui/AnimatedText';
import { Banner } from '@ui/Banner';
import { animations } from 'const/animations';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styles } from './styles';

export const Info: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <section id="info" css={styles.base}>
        <div css={styles.titleWrapper}>
          <h1 css={styles.title}>
            <AnimatedText
              text={formatMessage({ id: 'info.how' })}
              delay={0.1}
            />
          </h1>
          <p css={styles.paragraph}>
            <AnimatedText
              text={formatMessage({ id: 'info.steps' })}
              delay={0.2}
            />
          </p>
        </div>
        <div css={styles.list}>
          <motion.div
            {...animations.opacityInView({ delay: 0.1, margin: '0px' })}
            css={styles.imgContainer}
          >
            <div css={styles.overlay} />
            <Image
              src="/images/info-odaberi_sport.jpg"
              alt="Web app"
              width={320}
              height={320}
              loading="lazy"
            />
          </motion.div>
          <motion.div
            {...animations.opacityInView({ delay: 0.2, margin: '0px' })}
            css={styles.imgContainer}
          >
            <div css={styles.overlay} />
            <Image
              src="/images/info-pronadi_teren.jpg"
              alt="Web app"
              width={320}
              height={320}
              loading="lazy"
            />
          </motion.div>
          <motion.div
            {...animations.opacityInView({ delay: 0.3, margin: '0px' })}
            css={styles.imgContainer}
          >
            <div css={styles.overlay} />
            <Image
              src="/images/info-odaberi_termin.jpg"
              alt="Web app"
              width={320}
              height={320}
              loading="lazy"
            />
          </motion.div>
          <motion.div
            {...animations.opacityInView({ delay: 0.5, margin: '0px' })}
            css={styles.imgContainer}
          >
            <div css={styles.overlay} />
            <Image
              src="/images/info-rezerviraj.jpg"
              alt="Web app"
              width={320}
              height={320}
              loading="lazy"
            />
          </motion.div>
        </div>
        <div css={styles.buttonWrapper}>
          <motion.a
            href="#hero"
            {...animations.opacityInView({ delay: 0.5, margin: '0px' })}
            css={styles.button}
          >
            <motion.span>
              <FormattedMessage id="info.try" />
            </motion.span>
          </motion.a>
        </div>
      </section>
      <section css={styles.base}>
        <div css={styles.titleWrapper}>
          <h1 css={styles.title}>
            <AnimatedText
              text={formatMessage({ id: 'info.play-sports' })}
              delay={0.2}
            />
          </h1>
          <p css={styles.paragraph}>
            <AnimatedText
              text={formatMessage({ id: 'info.play-sports.description' })}
              delay={0.3}
            />
          </p>
        </div>
        <div css={styles.contentWrapper}>
          <motion.div
            {...animations.opacityInView({ delay: 0.2, margin: '0px' })}
            css={styles.upperWrapper}
          >
            <div css={styles.overlay} />
            <div css={styles.leftItem}>
              <Image
                src="/images/info-nogomet.jpg"
                alt="Web app"
                width={320}
                height={320}
                loading="lazy"
                css={styles.image}
              />
              <p css={styles.contentTitle}>
                <FormattedMessage id="football.title" />
              </p>
              <p css={styles.contentDescription}>
                <FormattedMessage id="football.description" />
              </p>
            </div>
            <div css={styles.overlay} />
            <div css={styles.rightItem}>
              <Image
                src="/images/info-tenis-test.jpg"
                alt="Web app"
                width={320}
                height={320}
                loading="lazy"
                css={styles.resizedImage}
              />
              <p css={styles.contentTitle}>
                <FormattedMessage id="squash-tennis.title" />
              </p>
              <p css={styles.contentDescription}>
                <FormattedMessage id="squash-tennis.description" />
              </p>
            </div>
          </motion.div>
          <motion.div
            {...animations.opacityInView({ delay: 0.1, margin: '0px' })}
            css={styles.lowerWrapper}
          >
            <div css={styles.overlay} />
            <div css={styles.rightItem}>
              <Image
                src="/images/info-grupni-test.jpg"
                alt="Web app"
                width={320}
                height={320}
                loading="lazy"
                css={styles.resizedImage}
              />
              <p css={styles.contentTitle}>
                <FormattedMessage id="group-training.title" />
                <sup css={styles.superscript}>
                  <FormattedMessage id="group-training.soon" />
                </sup>
              </p>
              <p css={styles.contentDescription}>
                <FormattedMessage id="group-training.description" />
              </p>
            </div>
            <div css={styles.overlay} />
            <div css={styles.leftItem}>
              <Image
                src="/images/info-borilacki.jpg"
                alt="Web app"
                width={320}
                height={320}
                loading="lazy"
                css={styles.image}
              />
              <p css={styles.contentTitle}>
                <FormattedMessage id="martial-arts.title" />
                <sup css={styles.superscript}>
                  <FormattedMessage id="martial-arts.soon" />
                </sup>
              </p>
              <p css={styles.contentDescription}>
                <FormattedMessage id="martial-arts.description" />
              </p>
            </div>
          </motion.div>
        </div>
      </section>
      <Banner />
    </>
  );
};
