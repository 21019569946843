import { css } from '@emotion/react';
import { button } from '@styles/button.styles';

const base = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 172px);
  color: var(--color-text-6);
  text-align: center;
`;

const errorMessage = css`
  padding-bottom: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const title = css`
  font-size: clamp(2rem, 0.06rem + 4.19vw, 4.25rem);
  margin: 12px;
`;

const subtitle = css`
  font-size: clamp(1rem, 0.64rem + 1.61vw, 2.25rem);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const errorDecscription = css`
  font-size: clamp(0.75rem, 0.69rem + 0.26vw, 1rem);
  line-height: 20px;
  font-weight: 400;
  color: var(--color-text-4);
  margin: 20px 0;
  white-space: pre;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const buttonWrapper = css`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const backButton = css`
  ${button.primary};
  grid-column: 2;
  padding: 12px 32px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: var(--color-text-1);
  background-color: var(--color-general-primary);

  border-radius: 12px;

  &:hover,
  &:focus,
  &:active {
    color: var(--color-text-1);
  }
`;

export const notfoundStyles = {
  base,
  errorMessage,
  errorDecscription,
  buttonWrapper,
  backButton,
  title,
  subtitle,
};
