import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';
import { breakpoints } from '@styles/variables.styles';
import { typo } from '@styles/typo.styles';

const base = css`
  ${grid.basic6col};
  margin-bottom: 100px;

  @media ${breakpoints.fromLrg} {
    ${grid.basic50col};
    margin-bottom: 180px;
    padding-top: 51px;
  }
`;

const contentWrapper = css`
  grid-column: 5 / span 42;
  display: flex;
  flex-direction: column;
  gap: 56px;
  height: auto;
`;

const textWrapper = css`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 8px;
`;

const mainTitle = css`
  color: var(--color-text-6);
  font-size: 52px;
  line-height: 56px;
  font-weight: 400;
  letter-spacing: -0.02em;
`;

const subtitle = css`
  ${typo.from16to14}
  color: var(--color-text-5);
  font-weight: 400;
`;

const reservedSpace = css`
  height: 320px;
  background: #756a6a;
`;

const image = css`
  position: relative;
  grid-column: 1 / span 6;
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin-top: 80px;

  img {
    border-radius: 6px;
    height: auto;
    aspect-ratio: 625 / 520;
  }

  @media ${breakpoints.fromLrg} {
    grid-column: 1 / span 19;
    width: calc(100% + 100px);
    margin-left: -25px;
    margin-top: 0;

    img {
      border-radius: 12px;
    }
  }
`;

const overlay = css`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color-background-1);
`;

const columnRight = css`
  grid-column: 1 / span 6;
  order: -1;

  @media ${breakpoints.fromLrg} {
    grid-column: 26 / span 21;
    order: unset;
  }
`;

const title = css`
  position: relative;
  font-size: clamp(2rem, 1.93rem + 0.32vw, 2.25rem);
  line-height: clamp(2.25rem, 2.18rem + 0.32vw, 2.5rem);
  letter-spacing: -0.03em;
  color: var(--color-text-6);
  font-weight: 400;

  & > span {
    display: block;
  }

  @media ${breakpoints.fromSml} {
    padding: 0 48px 12px;
  }
`;

const description = css`
  max-width: 420px;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-text-4);
  margin-bottom: 60px;

  & > span {
    display: block;
  }

  @media ${breakpoints.fromSml} {
    margin: 0 48px 60px;
  }
`;

const list = css`
  display: flex;
  row-gap: 40px;
  flex-direction: column;
  column-gap: 20px;
  margin-bottom: 64px;

  @media ${breakpoints.fromSml} {
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 60px;

    & > div {
      width: calc(50% - 10px);
    }
  }
`;

const listItem = css`
  display: flex;
  gap: 16px;
`;

const paragraphWrapper = css`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const listItemTitle = css`
  ${typo.from20to16};
  margin: 16px 0;
  font-weight: 700;
  color: var(--color-text-6);
`;

const listItemDescription = css`
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-4);
`;

const button = css`
  display: flex;
  position: relative;
  background-color: var(--color-general-primary);
  color: var(--color-text-1);
  width: max-content;
  font-size: 16px;
  line-height: 24px;
  border-radius: 12px;
  margin-left: 48px;
  padding: 10px 32px;
  overflow: hidden;
  cursor: pointer;

  > span,
  svg {
    z-index: 1;
    font-family: var(--font-family-primarySemiBold);
  }

  &::before {
    content: '';
    position: absolute;
    background-color: white;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 12px;
    transition: width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  }

  &:hover::before {
    width: 100%;
  }

  @media ${breakpoints.fromSml} {
  }
`;

const icon = css`
  margin: 16px 0;
`;

export const styles = {
  base,
  contentWrapper,
  textWrapper,
  mainTitle,
  subtitle,
  reservedSpace,
  image,
  overlay,
  columnRight,
  title,
  description,
  list,
  listItem,
  paragraphWrapper,
  listItemTitle,
  listItemDescription,
  button,
  icon,
};
