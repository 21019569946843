import { AnimatedText } from '@ui/AnimatedText';
import { animations } from 'const/animations';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styles } from './styles';

export const Hero: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <section id="hero" css={styles.base}>
      <div css={styles.wrapper}>
        <div css={styles.present}>
          <motion.p {...animations.opacity({})}>
            <FormattedMessage id="hero.intro" />
          </motion.p>
        </div>
        <h1 css={styles.title}>
          <AnimatedText
            text={formatMessage({ id: 'hero.title' })}
            delay={0.2}
          />
        </h1>
        <div css={styles.paragraphWrapper}>
          <p>
            <AnimatedText
              delay={1}
              text={formatMessage({ id: 'hero.paragraph1' })}
            />
          </p>
          <div css={styles.buttonWrapper}>
            <motion.a
              href="https://apps.apple.com/hr/app/playoff/id6449152333"
              {...animations.opacityInView({ duration: 2, delay: 2 })}
              css={styles.button}
              target="_blank"
            >
              <motion.span>
                <FormattedMessage id="hero.download-ios" />
              </motion.span>
            </motion.a>
            <motion.a
              href="https://play.google.com/store/apps/details?id=com.sportskiobjekti.app"
              {...animations.opacityInView({ duration: 2, delay: 2.2 })}
              css={styles.button}
              target="_blank"
            >
              <motion.span>
                <FormattedMessage id="hero.download-android" />
              </motion.span>
            </motion.a>
          </div>
        </div>
        <motion.div
          {...animations.opacityInView({
            duration: 1,
            amount: 0.1,
            delay: 1.6,
          })}
          css={styles.image}
        >
          <div css={styles.overlay} />
          <Image
            src="/images/hero-2.png"
            alt="Web app"
            width={960}
            height={592}
            fetchPriority="high"
            priority
          />
        </motion.div>
      </div>
    </section>
  );
};
