import { SerializedStyles } from '@emotion/react';
import { AnimatedText } from '@ui/AnimatedText';
import { animations } from 'const/animations';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FAQList } from './const';
import { FaqItem } from './fragments';
import { styles } from './styles';

const FeedbackIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/icon-feedback-24.svg'),
  {
    loading: () => null,
  },
);

const RoadmapIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/icon-bulb-24.svg'),
  {
    loading: () => null,
  },
);

export const Faq: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <section id="faq" css={styles.base}>
      <div css={styles.contentWrapper}>
        <motion.div css={styles.leftSide}>
          <h1 css={styles.title}>
            <AnimatedText
              text={formatMessage({ id: 'faq.title' })}
              delay={0.1}
            />
          </h1>
          <motion.p
            css={styles.description}
            {...animations.opacityInView({ delay: 1 })}
          >
            <FormattedMessage id="faq.help" />
            <a href="mailto:info@theplayoff.app" css={styles.contactLink}>
              <FormattedMessage id="faq.contact" />
            </a>
          </motion.p>
          <motion.div
            css={styles.feedbackContainer}
            {...animations.opacityInView({ delay: 1.5 })}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://playoff.featurebase.app/"
              css={[styles.feedbackItem, styles.feedback]}
            >
              <FeedbackIcon viewBox="0 0 24 24" />
              <span>
                <FormattedMessage id="faq.feedback" />
              </span>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://playoff.featurebase.app/roadmap"
              css={styles.roadmap}
            >
              <RoadmapIcon viewBox="0 0 24 24" />
              <span>
                <FormattedMessage id="faq.roadmap" />
              </span>
            </a>
          </motion.div>
        </motion.div>
        <motion.div
          css={styles.rightSide}
          {...animations.parentStaggerPropsInView}
        >
          {FAQList.map((item, index) => (
            <FaqItem
              key={`faq_${index}`}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </motion.div>
      </div>
    </section>
  );
};
