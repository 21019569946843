import { FC } from 'react';
import { Analytics } from './analytics';
import { Collaboration } from './collaboration';
import { Faq } from './faq';
import { Hero } from './hero';
import { Info } from './info';

export const PromoHomepage: FC = () => {
  return (
    <>
      <Hero />
      <Info />
      {/* <News /> */}
      {/* <Booking /> */}
      <Analytics />
      <Faq />
      <Collaboration />
    </>
  );
};
