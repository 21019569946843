import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';
import { typo } from '@styles/typo.styles';
import { breakpoints } from '@styles/variables.styles';

const base = css`
  ${grid.basic6col};
  margin-bottom: 100px;

  @media ${breakpoints.fromLrg} {
    ${grid.basic50col};
    margin-bottom: 200px;
  }
`;

const titleWrapper = css`
  grid-column: 1 / span 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${breakpoints.fromLrg} {
    grid-column: 12 / span 30;
  }
`;

const title = css`
  ${typo.from56to32}
  color: var(--color-text-6);

  font-weight: 400;
  margin-bottom: 8px;
  letter-spacing: -0.02em;
  text-align: center;

  & > span {
    justify-content: center;
  }
`;

const paragraph = css`
  color: var(--color-text-4);
  font-size: clamp(0.88rem, 0.84rem + 0.16vw, 1rem);
  line-height: clamp(1.25rem, 1.18rem + 0.32vw, 1.5rem);
  font-weight: 400;
  margin-bottom: 60px;
  text-align: center;

  & > span {
    justify-content: center;
  }
`;

const list = css`
  grid-column: 1 / span 6;
  display: flex;
  row-gap: 40px;
  flex-direction: column;
  column-gap: 20px;
  margin-bottom: 60px;
  align-items: center;
  flex-direction: row;
  width: 100%;
  overflow-y: auto;
  scrollbar-width: none;

  @media ${breakpoints.fromSml} {
    flex-direction: row;
    flex-wrap: wrap;
    width: unset;
    overflow-y: unset;

    justify-content: space-between;

    & > div {
      width: calc(50% - 10px);
    }
  }

  @media ${breakpoints.fromMed} {
    grid-column: 1 / span 6;
    flex-direction: row;
    flex-wrap: unset;
  }

  @media ${breakpoints.fromLrg} {
    grid-column: 5 / span 42;
    flex-direction: row;
  }
`;

const image = css`
  position: relative;
  grid-column: 1 / span 6;
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 30px 0 24px;
  border-radius: 15px;

  img {
    border-radius: 6px;
    aspect-ratio: 480 / 340;
    height: auto;
  }

  @media ${breakpoints.fromLrg} {
    margin-right: -220px;
    width: calc(100% + 100px);
    margin-top: 0px;
  }
`;
const imgContainer = css`
  flex-shrink: 0;
  @media ${breakpoints.fromSml} {
    flex-shrink: unset;
  }
`;

const resizedImage = css`
  ${image}

  @media ${breakpoints.toMed} {
    aspect-ratio: 480 / 340;
    object-fit: cover;
  }
`;

const overlay = css`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const buttonWrapper = css`
  grid-column: 3 / span 2;
  display: flex;
  justify-content: center;
  width: max-content;

  @media ${breakpoints.fromMed} {
    width: auto;
  }

  @media ${breakpoints.fromLrg} {
    grid-column: 24 / span 6;
    width: max-content;
  }
`;

const button = css`
  display: flex;
  position: relative;
  background-color: var(--color-general-primary);
  color: var(--color-text-1);
  width: max-content;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  border-radius: 12px;
  padding: 10px 32px;
  overflow: hidden;
  cursor: pointer;
  gap: 12px;

  > span,
  svg {
    z-index: 1;
    font-family: var(--font-family-primarySemiBold);
  }

  &::before {
    content: '';
    position: absolute;
    background-color: white;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 12px;
    transition: width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  }

  &:hover::before {
    width: 100%;
  }
`;

const contentWrapper = css`
  grid-column: 1 / span 6;
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media ${breakpoints.fromLrg} {
    grid-column: 5 / span 42;
  }
`;

const upperWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media ${breakpoints.fromMed} {
    flex-direction: row;
  }
`;

const leftItem = css`
  display: flex;
  flex-direction: column;

  @media ${breakpoints.fromMed} {
    width: 480px;
  }
`;

const rightItem = css`
  display: flex;
  flex-direction: column;
`;

const lowerWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-right: unset;

  @media ${breakpoints.fromMed} {
    flex-direction: row;
  }
`;

const contentTitle = css`
  ${typo.from40to24}
  color: var(--color-text-6);
  font-weight: 400;
  margin-bottom: 8px;
`;

const contentDescription = css`
  ${typo.from16to14}
  color: var(--color-text-5);
  font-weight: 400;
  white-space: pre-line;
`;

const superscript = css`
  margin-left: 8px;
  color: var(--color-general-primary);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

export const styles = {
  base,
  title,
  titleWrapper,
  paragraph,
  list,
  image,
  resizedImage,
  overlay,
  buttonWrapper,
  button,
  contentWrapper,
  upperWrapper,
  leftItem,
  rightItem,
  lowerWrapper,
  contentTitle,
  contentDescription,
  superscript,
  imgContainer,
};
