import { FormattedMessage } from 'react-intl';

export const FAQList: Array<{
  question: JSX.Element;
  answer: JSX.Element;
}> = Array.from({ length: 10 }).map((item, i) => {
  return {
    question: <FormattedMessage id={`faq.${i}.question`} />,
    answer: <FormattedMessage id={`faq.${i}.answer`} />,
  };
});
