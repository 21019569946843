import { Button } from '@ui/Button';
import router from 'next/router';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { notfoundStyles as styles } from './NotFound.styles';

export const NotFound: React.FC = () => {
  return (
    <div css={styles.base}>
      <div css={styles.errorMessage}>
        <h1 css={styles.title}>
          <FormattedMessage id="not-found" />
        </h1>
        <h2 css={styles.subtitle}>
          <FormattedMessage id="not-found.description" />
        </h2>
      </div>
      <p css={styles.errorDecscription}>
        <FormattedMessage id="not-found.instructions" />
      </p>
      <div css={styles.buttonWrapper}>
        <Button
          style="primary"
          customStyles={styles.backButton}
          onClick={() => router.push('/')}
        >
          <FormattedMessage id="not-found.button" />
        </Button>
      </div>
    </div>
  );
};
