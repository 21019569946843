import { SerializedStyles } from '@emotion/react';
import { AnimatedText } from '@ui/AnimatedText';
import { animations } from 'const/animations';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styles } from './styles';

const Label = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/label-icon.svg'),
  {
    loading: () => null,
  },
);

const AnalyticsLogo = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/linechart-icon.svg'),
  {
    loading: () => null,
  },
);

const Automation = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/card-icon.svg'),
  {
    loading: () => null,
  },
);

const Calendar = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/calendar_event-24.svg'),
  {
    loading: () => null,
  },
);

export const Analytics: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      {/* <section id="hosts" css={styles.base}>
        <div css={styles.contentWrapper}>
          <div css={styles.textWrapper}>
            <h1 css={styles.mainTitle}>Naši partneri</h1>
            <p css={styles.subtitle}>
              Dođite do željenih termina u nekoliko jednostavnih koraka:
            </p>
          </div>
          <div css={styles.reservedSpace}></div>
        </div>
      </section> */}
      <section id="hosts" css={styles.base}>
        <div css={styles.image}>
          <Image
            src="/images/analytics-1.jpg"
            alt="Web app"
            height={520}
            width={920}
            loading="lazy"
          />
          <motion.div
            {...animations.overlayInView({ duration: 1.2, delay: 0.4 })}
            css={styles.overlay}
          />
        </div>
        <div css={styles.columnRight}>
          <h2 css={styles.title}>
            <span>
              <AnimatedText
                text={formatMessage({ id: 'promo-homepage.analytics.title' })}
              />
            </span>
          </h2>
          <p css={styles.description}>
            <span>
              <AnimatedText
                text={formatMessage({
                  id: 'promo-homepage.analytics.description',
                })}
                delay={0.4}
              />
            </span>
          </p>
          <div css={styles.list}>
            <motion.div
              {...animations.opacityInView({ delay: 0.2, margin: '0px' })}
              css={styles.listItem}
            >
              <Label viewBox="0 0 32 32" css={styles.icon} />
              <div css={styles.paragraphWrapper}>
                <p css={styles.listItemTitle}>
                  <FormattedMessage id="promo-homepage.analytics.promotion.title" />
                </p>
                <p css={styles.listItemDescription}>
                  <FormattedMessage id="promo-homepage.analytics.promotion.description" />
                </p>
              </div>
            </motion.div>
            <motion.div
              {...animations.opacityInView({ delay: 0.4, margin: '0px' })}
              css={styles.listItem}
            >
              <AnalyticsLogo viewBox="0 0 32 32" css={styles.icon} />
              <div css={styles.paragraphWrapper}>
                <p css={styles.listItemTitle}>
                  <FormattedMessage id="promo-homepage.analytics.usage" />
                </p>
                <p css={styles.listItemDescription}>
                  <FormattedMessage id="promo-homepage.analytics.usage.description" />
                </p>
              </div>
            </motion.div>
            <motion.div
              {...animations.opacityInView({ delay: 0.6, margin: '0px' })}
              css={styles.listItem}
            >
              <Automation viewBox="0 0 32 32" css={styles.icon} />
              <div css={styles.paragraphWrapper}>
                <p css={styles.listItemTitle}>
                  <FormattedMessage id="promo-homepage.analytics.automated-payment" />
                </p>
                <p css={styles.listItemDescription}>
                  <FormattedMessage id="promo-homepage.analytics.automated-payment.description" />
                </p>
              </div>
            </motion.div>
            <motion.div
              {...animations.opacityInView({ delay: 0.8, margin: '0px' })}
              css={styles.listItem}
            >
              <Calendar viewBox="0 0 24 24" css={styles.icon} />
              <div css={styles.paragraphWrapper}>
                <p css={styles.listItemTitle}>
                  <FormattedMessage id="promo-homepage.analytics.calendar-view" />
                </p>
                <p css={styles.listItemDescription}>
                  <FormattedMessage id="promo-homepage.analytics.calendar-view.description" />
                </p>
              </div>
            </motion.div>
          </div>
          <motion.a
            href="mailto:info@theplayoff.app"
            {...animations.opacityInView({ delay: 1, margin: '0px' })}
            css={styles.button}
          >
            <motion.span>
              <FormattedMessage id="promo-homepage.analytics.become-partner" />
            </motion.span>
          </motion.a>
        </div>
      </section>
    </>
  );
};
