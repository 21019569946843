import { Button } from '@ui/Button';
import { animations } from 'const';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { styles } from '../styles';

interface Props {
  question: JSX.Element;
  answer: JSX.Element;
}

export const FaqItem: React.FC<Props> = ({ question, answer }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <motion.div css={styles.faqItem} variants={animations.childStagger}>
      <motion.div
        css={styles.faqQuestion}
        onClick={() => setExpanded(!expanded)}
      >
        <span>{question}</span>
        <Button customStyles={styles.toggleButton}>
          {expanded ? (
            <motion.svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              animate={{ rotate: 0 }}
              transition={{
                duration: 0.1,
                ease: 'easeOut',
              }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Minus">
                <path
                  id="Vector"
                  d="M2.5 8L13.5 8"
                  stroke="#B7BAB2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </motion.svg>
          ) : (
            <motion.svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              animate={{ rotate: 90 }}
              transition={{
                duration: 0.1,
                ease: 'easeOut',
              }}
            >
              <g id="e-add 1">
                <g id="Group">
                  <path
                    id="Union"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.5 1.5C8.5 1.22386 8.27614 1 8 1C7.72386 1 7.5 1.22386 7.5 1.5V7.5H1.5C1.22386 7.5 1 7.72386 1 8C1 8.27614 1.22386 8.5 1.5 8.5H7.5V14.5C7.5 14.7761 7.72386 15 8 15C8.27614 15 8.5 14.7761 8.5 14.5V8.5H14.5C14.7761 8.5 15 8.27614 15 8C15 7.72386 14.7761 7.5 14.5 7.5H8.5V1.5Z"
                    fill="#B7BAB2"
                  />
                </g>
              </g>
            </motion.svg>
          )}
        </Button>
      </motion.div>
      <div
        onClick={() => setExpanded(!expanded)}
        css={expanded ? styles.answerExpanded : styles.answer}
      >
        <div>{answer}</div>
      </div>
      <div css={styles.border} />
    </motion.div>
  );
};
