import { SerializedStyles } from '@emotion/react';
import { useFunction } from '@modules/firebase';
import { AnimatedText } from '@ui/AnimatedText';
import { animations } from 'const/animations';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'sonner';
import { styles } from './styles';

const FacebookIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/facebook-16.svg'),
  {
    loading: () => null,
  },
);
const InstagramIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/instagram-16.svg'),
  {
    loading: () => null,
  },
);
const XIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/X-16.svg'),
  {
    loading: () => null,
  },
);
// const TikTokIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
//   () => import('public/icons/tiktok-24.svg'),
//   {
//     loading: () => null,
//   },
// );
// const LinkedInIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
//   () => import('public/icons/linkedin-24.svg'),
//   {
//     loading: () => null,
//   },
// );

export const Collaboration: FC = () => {
  const { formatMessage } = useIntl();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [privacy, setPrivacy] = useState(false);

  const sendContactUsInquiryCallable = useFunction<
    ContactUsFormData,
    undefined,
    string
  >('sendContactUsInquiry');

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    try {
      if (!privacy) {
        toast.error(formatMessage({ id: 'collaboration.toast.privacy.error' }));
        return;
      }
      const data: ContactUsFormData = {
        name,
        email,
        message,
      };

      await sendContactUsInquiryCallable(data);
      toast.success(formatMessage({ id: 'collaboration.toast.success' }));

      setName('');
      setEmail('');
      setMessage('');
      setPrivacy(false);
    } catch (error) {
      toast.error(formatMessage({ id: 'collaboration.toast.error' }));
    }
  }

  return (
    <section id="contact" css={styles.base}>
      <div css={styles.content}>
        <div css={styles.leftSideWrapper}>
          <h2 css={styles.title}>
            <AnimatedText
              text={formatMessage({ id: 'collaboration.title' })}
              delay={0.2}
            />
          </h2>
          <p css={styles.paragraph}>
            <AnimatedText
              text={formatMessage({ id: 'collaboration.subtitle' })}
              delay={0.3}
            />
          </p>
          <div css={styles.socialsWrapper}>
            <motion.a
              href="https://www.facebook.com/theplayoffapp"
              target="_blank"
              rel="noopener noreferrer"
              {...animations.opacityInView({ delay: 0.4 })}
            >
              <FacebookIcon css={styles.icon} viewBox="0 -2 16 19" />
            </motion.a>
            <motion.a
              href="https://www.instagram.com/theplayoff.app/"
              target="_blank"
              rel="noopener noreferrer"
              {...animations.opacityInView({ delay: 0.6 })}
            >
              <InstagramIcon css={styles.icon} viewBox="0 -2 16 19" />
            </motion.a>
            <motion.a
              href="https://www.twitter.com/playoffcroatia/"
              target="_blank"
              rel="noopener noreferrer"
              {...animations.opacityInView({ delay: 0.8 })}
            >
              <XIcon css={styles.icon} viewBox="0 -2 16 19" />
            </motion.a>
            {/* TODO: Add once we have LinkedIn profile
            <motion.a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              {...animations.opacityInView({ delay: 0.8 })}
            >
              <LinkedInIcon css={styles.icon} viewBox="0 -2 24 24" />
            </motion.a> */}
            {/* <motion.a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              {...animations.opacityInView({ delay: 0.8 })}
            >
              <TikTokIcon css={styles.icon} viewBox="0 -2 24 24" />
            </motion.a> */}
          </div>
        </div>
        <motion.form
          {...animations.parentStaggerPropsInView}
          css={styles.rightSideWrapper}
          onSubmit={handleSubmit}
        >
          <motion.div
            variants={animations.childStagger}
            css={styles.formInputs}
          >
            <label htmlFor="name" css={styles.label}></label>
            <input
              type="text"
              css={styles.input}
              placeholder={formatMessage({
                id: 'collaboration.placeholder.name',
              })}
              value={name}
              onChange={(e) => setName(e.target.value)}
              id="name"
              required
            />
          </motion.div>
          <motion.div
            variants={animations.childStagger}
            css={styles.formInputs}
          >
            <label htmlFor="email" css={styles.label}></label>
            <input
              type="email"
              css={styles.input}
              placeholder={formatMessage({
                id: 'collaboration.placeholder.email',
              })}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              required
            />
          </motion.div>
          <motion.div
            variants={animations.childStagger}
            css={styles.formTextareaWrapper}
          >
            <label htmlFor="message"></label>
            <textarea
              name="message"
              css={styles.textarea}
              placeholder={formatMessage({
                id: 'collaboration.placeholder.message',
              })}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              id="message"
              rows={5}
              required
            />
          </motion.div>
          <motion.div
            variants={animations.childStagger}
            css={styles.privacyWrapper}
          >
            <input
              type="checkbox"
              css={styles.checkbox}
              checked={privacy}
              onChange={(event) => setPrivacy(event.target.checked)}
              id="privacy"
            />
            <label htmlFor="privacy" css={styles.privacyDescription}>
              <FormattedMessage id="collaboration.terms-of-service1" />{' '}
              <a href="privacy-policy" css={styles.privacyLink}>
                <FormattedMessage id="collaboration.terms-of-service2" />
              </a>
              .
            </label>
          </motion.div>
          <motion.button type="submit" css={styles.button}>
            <motion.span>
              <FormattedMessage id="collaboration.button" />
            </motion.span>
          </motion.button>
        </motion.form>
      </div>
    </section>
  );
};
