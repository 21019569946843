import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';
import { typo } from '@styles/typo.styles';
import { breakpoints } from '@styles/variables.styles';

const base = css`
  width: 100%;
  display: flex;
  justify-content: center;
  background-image: url('/images/gradient.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  overflow: visible;
  margin: 80px 0 194px;
`;

const wrapper = css`
  ${grid.basic6col};
  margin: 0;

  @media ${breakpoints.fromLrg} {
    ${grid.basic50col};
  }
`;

const title = css`
  ${typo.from68to36};
  grid-column: 1 / span 6;
  color: var(--color-text-6);
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
  letter-spacing: -0.03em;

  & > span {
    justify-content: center;
  }

  @media ${breakpoints.fromLrg} {
    grid-column: 8 / span 36;
  }
`;

const present = css`
  grid-column: 1 / span 6;
  display: flex;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 3px;
  color: var(--color-text-white);
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;

  p {
    padding: 8px 20px;
    background-color: var(--color-background-3);
  }

  @media ${breakpoints.fromLrg} {
    grid-column: 1 / span 50;
  }
`;

const paragraphWrapper = css`
  ${typo.from20to16};
  grid-column: 1 / span 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--color-text-5);
  margin-bottom: 68px;
  text-align: center;

  & > p > span {
    justify-content: center;
  }

  @media ${breakpoints.fromLrg} {
    grid-column: 1 / span 50;
    margin-bottom: 77px;
  }
`;

const image = css`
  position: relative;
  grid-column: 1 / span 6;
  border-radius: 4px 4px 0 0;
  overflow: hidden;

  img {
    border-radius: 4px;
    aspect-ratio: 960 / 590;
  }

  @media ${breakpoints.fromLrg} {
    grid-column: 11 / span 30;
    border-radius: 10px 10px 0 0;

    img {
      border-radius: 10px;
    }
  }
`;

const overlay = css`
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(22, 20, 27, 0) 0%,
    #16141b 85.94%
  );
`;

const buttonWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  gap: 24px;

  @media ${breakpoints.from483} {
    flex-direction: row;
    justify-content: center;
  }
`;

const button = css`
  display: flex;
  justify-content: center;
  position: relative;
  background-color: var(--color-general-primary);
  color: var(--color-text-1);
  font-size: 16px;
  line-height: 24px;
  border-radius: 12px;
  padding: 10px 32px;
  gap: 12px;
  overflow: hidden;
  cursor: pointer;

  > span,
  svg {
    z-index: 1;
    font-family: var(--font-family-primarySemiBold);
  }

  &::before {
    content: '';
    position: absolute;
    background-color: white;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 12px;
    transition: width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  }

  &:hover::before {
    width: 100%;
  }
`;

export const styles = {
  base,
  title,
  present,
  paragraphWrapper,
  image,
  overlay,
  wrapper,
  buttonWrapper,
  button,
};
